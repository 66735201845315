<template>
  <div id="demo-basic-card">
    <div class="vx-row">

      <!-- USER PROFILE CARD 2 - MINIMAL -->
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">

        <vx-card class="p-2">
          <router-link :to="{ name: 'learning-center-presets-assessment-subject'}" class="flex align-items-center justify-content-center p-5">
            <h2>Assessment Subjects</h2>
          </router-link>
        </vx-card>

      </div>
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card class="p-2">
          <router-link :to="{ name: 'learning-center-presets-eylf'}" class="flex align-items-center justify-content-center p-5">
            <h2>Early Years Learning Framework</h2>
          </router-link>
        </vx-card>
      </div>

    </div>
    <div class="vx-row">

      <!-- USER PROFILE CARD 2 - MINIMAL -->
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card class="p-2">
          <router-link :to="{ name: 'learning-center-presets-fast-check'}" class="flex align-items-center justify-content-center p-5">
            <h2>Fast Check</h2>
          </router-link>
        </vx-card>
      </div>
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card class="p-2">
<!--          <router-link :to="{ name: 'learning-center-presets-children-stories'}" class="text-center">-->
          <router-link :to="{ name: 'learning-center-presets-children-stories'}" class="flex align-items-center justify-content-center p-5">
            <h2>Children's Stories</h2>
          </router-link>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    components: {
      VuePerfectScrollbar,
    },
  }
</script>

<style lang="scss">
  #demo-basic-card {
    .overlay-card {
      .vx-card__collapsible-content {
        max-height: 485px;
      }
    }

    .chat-card-log {
      height: 360px;

      .chat-sent-msg {
        background-color: #f2f4f7 !important;
      }
    }

    .card-video {
      .video-js {
        height: 370px;
      }
    }
  }
</style>
